<!--
 * @Author: xialijie xy1111@vip.qq.com
 * @Date: 2022-02-17 11:50:23
 * @LastEditors: xialijie xy1111@vip.qq.com
 * @LastEditTime: 2022-07-06 14:42:14
 * @FilePath: \gzb-system-mobile-vue\src\views\contract\detail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="van-main contract-detail">
    <van-tabs v-model="active">
      <van-tab title="合同信息">
        <div class="van-common-detail">
          <van-cell title="合同类型" :value="utils.statusFormat(contractForm.contractType, 'ContractType_' + contractForm.contractClassification)" />
          <van-cell title="合同大分类" :value="utils.statusFormat(contractForm.contractClassification, 'ContractType')" />
          <van-cell title="合同名称" :value="utils.isEffectiveCommon(contractForm.contractName)" />
          <van-cell title="合同编号" :value="utils.isEffectiveCommon(contractForm.contractNumber)" />
          <van-cell title="所属订单" :value="contractForm.orderName || contractForm.orderNo ? utils.isEffectiveCommon(contractForm.orderName) + '(' + utils.isEffectiveCommon(contractForm.orderNo) + ')':'-'" />
          <van-cell title="所属项目" :value="utils.isEffectiveCommon(contractForm.projectName) + '(' + utils.isEffectiveCommon(contractForm.projectNo) + ')'" />
          <van-cell title="合同有效期" :value="utils.dateFormat(contractForm.contractDateStart) + '-' + utils.dateFormat(contractForm.contractDateEnd)" />
          <van-cell-group title="签署方">
            <van-cell v-for="signInfo in contractForm.signInfoList" :key="signInfo.projectNo" :title="signInfo.signatory" :value="signInfo.orgName" :label="utils.statusFormat(signInfo.natureSignatory, 'natureSignatory')" />
          </van-cell-group>
          <van-cell title="签署方式" :value="utils.isEffectiveCommon(contractForm.signingMethod)" />
          <van-cell v-if="contractForm.signDate || $route.name === 'contractAudit'" title="签署时间" :value="utils.dateFormat(contractForm.signDate)" />
          <file-list v-if="$route.name === 'contractAudit'" :file-list="sealFileList" title="已盖章合同" />
          <file-list :file-list="fileList" title="用印申请合同" />
        </div>
      </van-tab>
      <van-tab title="审核意见">
        <workflow-audit v-if="$route.query.taskViewMode" page-key="contract_seal" :business-key="contractForm.sealId" :proc-inst-id="$route.query.procInstId" :view-mode="true" @confirmAudit="utils.backReturn($route.query.messageType)" />
        <workflow-audit v-else :task-id="$route.query.taskId" page-key="contract_seal" :business-key="contractForm.sealId" @confirmAudit="utils.backReturn($route.query.messageType)" />
      </van-tab>
    </van-tabs>
    <back-button />
  </div>
</template>

<script>
import { Cell, CellGroup, Divider, Button, Tab, Tabs, Toast, uploader } from 'vant'
import WorkflowAudit from '@/components/workflow-audit'
import BackButton from '@/components/back-button'
import fileList from '@/components/file-list'
export default {
  components: {
    BackButton,
    WorkflowAudit,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Toast.name]: Toast,
    [uploader.name]: uploader,
    fileList
  },
  data () {
    return {
      id: this.$route.query.id,
      active: 0,
      contractForm: {
        signInfoList: [],
        fileDetailInfoVoApply: null,
        fileDetailInfoVoSeal: null
      },
      fileList: []
    }
  },
  computed: {
    sealFileList: function () {
      if (this.contractForm.fileDetailInfoVoSeal) {
        return [this.contractForm.fileDetailInfoVoSeal]
      } else {
        return []
      }
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      this.api.contract.detail(this.$route.query.id).then(result => {
        this.contractForm = result.data.value
        const fileList = []
        if (this.contractForm.fileDetailInfoVoApply) {
          fileList.push(this.contractForm.fileDetailInfoVoApply)
        }
        this.fileList = fileList
      }).finally(() => {
      })
    }
  }
}
</script>

<style lang="less" scoped>
.contract-detail {
  .van-tabs {
      margin-bottom: 50px;
  }
}
</style>
