var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main contract-detail" },
    [
      _c(
        "van-tabs",
        {
          model: {
            value: _vm.active,
            callback: function($$v) {
              _vm.active = $$v
            },
            expression: "active"
          }
        },
        [
          _c("van-tab", { attrs: { title: "合同信息" } }, [
            _c(
              "div",
              { staticClass: "van-common-detail" },
              [
                _c("van-cell", {
                  attrs: {
                    title: "合同类型",
                    value: _vm.utils.statusFormat(
                      _vm.contractForm.contractType,
                      "ContractType_" + _vm.contractForm.contractClassification
                    )
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "合同大分类",
                    value: _vm.utils.statusFormat(
                      _vm.contractForm.contractClassification,
                      "ContractType"
                    )
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "合同名称",
                    value: _vm.utils.isEffectiveCommon(
                      _vm.contractForm.contractName
                    )
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "合同编号",
                    value: _vm.utils.isEffectiveCommon(
                      _vm.contractForm.contractNumber
                    )
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "所属订单",
                    value:
                      _vm.contractForm.orderName || _vm.contractForm.orderNo
                        ? _vm.utils.isEffectiveCommon(
                            _vm.contractForm.orderName
                          ) +
                          "(" +
                          _vm.utils.isEffectiveCommon(
                            _vm.contractForm.orderNo
                          ) +
                          ")"
                        : "-"
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "所属项目",
                    value:
                      _vm.utils.isEffectiveCommon(
                        _vm.contractForm.projectName
                      ) +
                      "(" +
                      _vm.utils.isEffectiveCommon(_vm.contractForm.projectNo) +
                      ")"
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "合同有效期",
                    value:
                      _vm.utils.dateFormat(_vm.contractForm.contractDateStart) +
                      "-" +
                      _vm.utils.dateFormat(_vm.contractForm.contractDateEnd)
                  }
                }),
                _c(
                  "van-cell-group",
                  { attrs: { title: "签署方" } },
                  _vm._l(_vm.contractForm.signInfoList, function(signInfo) {
                    return _c("van-cell", {
                      key: signInfo.projectNo,
                      attrs: {
                        title: signInfo.signatory,
                        value: signInfo.orgName,
                        label: _vm.utils.statusFormat(
                          signInfo.natureSignatory,
                          "natureSignatory"
                        )
                      }
                    })
                  }),
                  1
                ),
                _c("van-cell", {
                  attrs: {
                    title: "签署方式",
                    value: _vm.utils.isEffectiveCommon(
                      _vm.contractForm.signingMethod
                    )
                  }
                }),
                _vm.contractForm.signDate || _vm.$route.name === "contractAudit"
                  ? _c("van-cell", {
                      attrs: {
                        title: "签署时间",
                        value: _vm.utils.dateFormat(_vm.contractForm.signDate)
                      }
                    })
                  : _vm._e(),
                _vm.$route.name === "contractAudit"
                  ? _c("file-list", {
                      attrs: {
                        "file-list": _vm.sealFileList,
                        title: "已盖章合同"
                      }
                    })
                  : _vm._e(),
                _c("file-list", {
                  attrs: { "file-list": _vm.fileList, title: "用印申请合同" }
                })
              ],
              1
            )
          ]),
          _c(
            "van-tab",
            { attrs: { title: "审核意见" } },
            [
              _vm.$route.query.taskViewMode
                ? _c("workflow-audit", {
                    attrs: {
                      "page-key": "contract_seal",
                      "business-key": _vm.contractForm.sealId,
                      "proc-inst-id": _vm.$route.query.procInstId,
                      "view-mode": true
                    },
                    on: {
                      confirmAudit: function($event) {
                        return _vm.utils.backReturn(
                          _vm.$route.query.messageType
                        )
                      }
                    }
                  })
                : _c("workflow-audit", {
                    attrs: {
                      "task-id": _vm.$route.query.taskId,
                      "page-key": "contract_seal",
                      "business-key": _vm.contractForm.sealId
                    },
                    on: {
                      confirmAudit: function($event) {
                        return _vm.utils.backReturn(
                          _vm.$route.query.messageType
                        )
                      }
                    }
                  })
            ],
            1
          )
        ],
        1
      ),
      _c("back-button")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }